import React from 'react';
import clsx from 'clsx';
import { AppBrandConfigs } from 'AppBranding';
import { type ProgramType } from 'Program';
import { useTranslation } from 'react-i18next';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type ChooseInstitutionConfig } from '../ChooseInstitution.types';
import { type InstitutionId } from '../../Institutions.types';

type InstitutionItemProps = {
    config: ChooseInstitutionConfig;
    onApplyClick: (programType: ProgramType, institutionId: InstitutionId) => void;
    loadingInstitutionId: InstitutionId | null;
};

const classes = {
    container: clsx([
        'flex',
        'w-full',
        'max-w-[340px]',
        'flex-col',
        'items-center',
        'rounded',
        'p-[20px]',
        'sm:p-[30px]',
    ]),
    logo: {
        container: clsx(['mb-[16px]', 'flex', 'items-end', 'justify-center', 'h-[40px]']),
    },
    heading: clsx(['mb-[15px]', 'text-center', 'text-[15px]', 'font-semibold', 'text-white']),
    description: {
        container: clsx(['mb-[20px]', 'flex', 'flex-col', 'justify-between', 'gap-[22px]']),
        paragraph: clsx(['text-center', 'text-[15px]', 'leading-[22px]', 'text-white']),
    },
    applyButton: clsx([
        'flex',
        'h-[35px]',
        'w-[203px]',
        'items-center',
        'justify-center',
        'rounded-full',
        'bg-white',
        'text-[15px]',
        'font-semibold',
        'text-black',
        'disabled:opacity-80',
        'disabled:cursor-not-allowed',
    ]),
};

export function InstitutionItem({ config, onApplyClick, loadingInstitutionId }: InstitutionItemProps) {
    const { t } = useTranslation('back_royal');

    const itemIsLoading = loadingInstitutionId === config.institutionId;

    return (
        <div className={clsx(classes.container, config.containerClassnames)}>
            <h3 className="sr-only">{AppBrandConfigs[config.institutionId].brandNameStandard}</h3>
            <div className={classes.logo.container}>
                <img
                    className={clsx(config.logoClassnames)}
                    src={config.logo}
                    alt={`${AppBrandConfigs[config.institutionId].brandNameStandard} logo`}
                />
            </div>
            <h4 className={classes.heading}>
                {t(config.degreeHeadingLocale.key, config.degreeHeadingLocale.params ?? {})}
            </h4>
            <div className={classes.description.container}>
                {config.descriptionParagraphs.map(paragraph => (
                    <p key={paragraph.key} className={classes.description.paragraph}>
                        {t(paragraph.key, paragraph.params ?? {})}
                    </p>
                ))}
            </div>
            <button
                type="button"
                data-testid="apply-button"
                className={classes.applyButton}
                onClick={() => onApplyClick(config.defaultProgramType, config.institutionId)}
                disabled={!!loadingInstitutionId}
            >
                {itemIsLoading ? (
                    <FrontRoyalSpinner className="no-delay no-top-margin mt-[5px]" color={config.spinnerColor} />
                ) : (
                    t('choose_institution.choose_institution.apply_now')
                )}
            </button>
        </div>
    );
}
