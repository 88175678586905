import { AppBrandConfigs } from 'AppBranding';
import { ProgramType } from 'Program';
import quanticLogo from 'images/choose-institution/quantic-logo-white.svg';
import valarTertiaryDarkBg from 'images/choose-institution/valar-logo-tertiary-darkbg.svg';
import { InstitutionId } from '../Institutions.types';
import { type ChooseInstitutionConfig } from './ChooseInstitution.types';

export const chooseInstitutionConfig = async (): Promise<Partial<Record<InstitutionId, ChooseInstitutionConfig>>> => ({
    [InstitutionId.quantic]: {
        institutionId: InstitutionId.quantic,
        containerClassnames: ['bg-coral'],
        logo: quanticLogo,
        logoClassnames: ['h-[39px]', 'w-[160px]'],
        degreeHeadingLocale: {
            key: 'choose_institution.choose_institution.degree_heading_quantic',
        },
        descriptionParagraphs: [
            {
                key: 'choose_institution.choose_institution.description_quantic_1',
                params: { degreeDurationMonths: AppBrandConfigs[InstitutionId.quantic].degreeProgramDurationMonths },
            },
            {
                key: 'choose_institution.choose_institution.description_quantic_2',
            },
        ],
        defaultProgramType: ProgramType.emba,
        spinnerColor: 'force-coral',
    },
    [InstitutionId.valar]: {
        institutionId: InstitutionId.valar,
        containerClassnames: ['bg-blue'],
        logo: valarTertiaryDarkBg,
        logoClassnames: ['h-[25px]', 'w-[240px]'],
        degreeHeadingLocale: {
            key: 'choose_institution.choose_institution.degree_heading_valar',
        },
        descriptionParagraphs: [
            {
                key: 'choose_institution.choose_institution.description_valar_1',
                params: { degreeDurationMonths: AppBrandConfigs[InstitutionId.valar].degreeProgramDurationMonths },
            },
            {
                key: 'choose_institution.choose_institution.description_valar_2',
            },
        ],
        defaultProgramType: ProgramType.emba_strategic_leadership,
        spinnerColor: 'blue',
    },
});
